<template>
  <section class="py-3 py-md-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-12 col-xl-12 col-xxl-12">
        <div class="row mb-3">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    
                    <th scope="col" class="text-uppercase">Product</th>
                    <th scope="col" class="text-uppercase text-center ">Qty</th>
                    <th scope="col" class="text-uppercase text-end">Unit Price</th>
                    <th scope="col" class="text-uppercase text-end">Amount</th>
                  </tr>
                </thead>
                <tbody  v-if="props.payment_type=='included_did'" class="table-group-divider">
                  <tr>
                    <td>Call Center (Monthly Subscription fee)</td>
                    <td class="text-center">1</td>
                    <td class="text-end">${{props.modelValue.subscription}}.00</td>
                    <td class="text-end">${{props.modelValue.subscription}}.00</td>
                  </tr>

                  <tr>
                    <td>Phone Number (included into subscribtion fee)</td>
                    <td class="text-center">1</td>
                    <td class="text-end">$0.00</td>
                    <td class="text-end">$0.00</td>
                  </tr>

                  <tr>
                    <td>${{props.modelValue.subscription}} Traffic (Included in monthly subscription fee)<br><em>(1000 min / ~16.5 hours in United States)</em></td>
                    <td class="text-center">1</td>
                    <td class="text-end">$0.00</td>
                    <td class="text-end">$0.00</td>
                  </tr>
                  <tr>
                    <th scope="row" colspan="3" class="text-uppercase text-end">Total</th>
                    <td class="text-end">${{props.modelValue.subscription}}.00</td>
                  </tr>
                </tbody>

                <tbody  v-if="props.payment_type=='additional_did'" class="table-group-divider">
                  <tr>
                    <td>Additional Phone Number (Monthly Subscription fee)</td>
                    <td class="text-center">1</td>
                    <td class="text-end">${{props.modelValue.subscription}}.00</td>
                    <td class="text-end">${{props.modelValue.subscription}}.00</td>
                  </tr>
                </tbody>


              </table>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</section>
<div v-if="props.payment_type=='initial'">
  <h5>Automatic top-up</h5>
  <p>
    To ensure uninterrupted service, you can enable automatic top-up for your account. This way, we will recharge your balance in advance, preventing any disruptions due to insufficient funds. Any topped-up balance will carry over to the following months and will not be deducted as part of your subscription fee.
  </p>
  <div class="row  mt-4">
    <div class="col-xl-5 col-lg-5 col-sm-5">
      <div class="input-group">
        <div class="input-group-text">
          <label class="switch s-primary mb-0">
            <input type="checkbox" v-model="topUpParams.allowed" />
            <span class="slider round"></span>
          </label>
        </div>
        <input v-model="topUpParams.topup" type="number" :disabled="topUpParams.allowed == false" class="form-control" placeholder="Switch" aria-label="switch"/>
        <span class="input-group-text">$</span>
      </div>
    </div>
  </div>
</div>
     
<!-- {{topUpParams}} -->
<!-- {{props.modelValue}} -->
</template>

<script setup>
  
  const emit = defineEmits(['update:modelValue']);

  const props = defineProps({
    modelValue: { type: Object, default: {} },
    payment_type: {type: String, default:"included_did"}
});
const topUpParams = ref(props.modelValue)
watch(topUpParams, (newValue) => {
    emit('update:modelValue', newValue);
}, { deep: true });

</script>