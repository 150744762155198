<style>
.header-container {
    z-index: 1030;
    border-bottom: 1px solid #034ecc;
}

    #my_blue_nav li>a:hover {
        background-color: blue;
    }

    #my_blue_nav li.active a {
        background-color: blue !important;
    }

    #privacyWrapper .privacyContent {
        margin-top: 0px;
    }

    .navbar .theme-brand li.theme-logo img {

        height: 50px;
    }

    .navbar {
        background: #ffffff;
    }
</style>

<style scoped>
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link svg {
    color: rgb(37, 97, 208);
    width: 22px;
    height: 22px;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .media.server-log svg {
    color: #004d96;
    fill: rgb(0 63 150 / 37%);
}
</style>

<template>
    <client-only>

    <ModalWindow
        :title="modalTitle"
        ref="PaymentModal" 
        mode=""
        :showCustomButton="true"
        :customButtonCaption="nextButtonCaption"
        :onCustomButtonClick = "next"
    >
    
        <twilioSelectDID
            ref="phoneSelector" 
            v-model= "paymentParams.SelectedNumber"
            v-if="screen=='number_selection'"
        />    
        <PaymentInvoice 
            
            v-model="paymentParams.paymentSettings"
            v-if="screen=='invoice'"
            :payment_type="payment_type"
        
        />
    </ModalWindow>

    <div>
        <div class="header-container">
            <header class="header navbar navbar-expand" style="margin-left:1px;margin-right:1px">
                <ul class="navbar-item theme-brand flex-row text-center mt-3  d-none d-md-block">
                        <router-link to="/">
                            <img src="~/assets/images/logo.png" class="navbar-logo d-md-*" alt="logo" style="height:50px;margin-top:0px;" />
                        </router-link>
                </ul>
                <ul class="navbar-item theme-brand flex-row text-center mt-3 d-block d-md-none ">
                        <router-link to="/">
                            <img src="~/assets/images/logo-mob.png" class="navbar-logo d-sm-block" alt="logo" style="height:50px;margin-top:0px;" />
                        </router-link>
                </ul>
             
                        
                <div class="navbar-item flex-row ms-auto">
                    <!-- <div class="dropdown nav-item notification-dropdown btn-group">
                        <div v-if="user.is_authorized" class="text-primary" style="font-size:13px;  text-transform: uppercase;">
                            <strong>{{ company.name }}</strong>
                        </div>
                    </div> -->
                    <div class="dropdown nav-item notification-dropdown btn-group">
                        <a href="javascript:;" id="ddlnotify" data-bs-toggle="dropdown" aria-expanded="true" class="btn dropdown-toggle btn-icon-only nav-link show">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu" data-v-5522efca=""><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings" data-v-5522efca=""><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg> -->
                            
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddlnotify" data-bs-popper="none">
                            <li role="presentation">
                                <a href="javascript:;" class="dropdown-item">
                                    <div class="media server-log">
                                        <div class="media-aside align-self-start">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users" data-v-5522efca=""><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                                        </div>
                                        <div class="media-body">
                                            <div class="data-info">
                                                <h6>Account Settings</h6>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </a>
                            </li>

                            <li role="presentation">
                                <hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider">
                            </li>

                            <li role="presentation">
                                <a href="javascript:;" @click="openStripePotal" class="dropdown-item">
                                    <div class="media server-log ">
                                        <div class="media-aside align-self-start">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card" data-v-5522efca=""><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>
                                        </div>
                                        <div class="media-body ">
                                            <div class="data-info ">
                                                <h6 class="mt-1 ">Billing & Payments</h6>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li role="presentation">
                                <hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider">
                            </li>

                            <li role="presentation">
                                <a href="javascript:;" class="dropdown-item">
                                    <div class="media server-log ">
                                        <div class="media-aside align-self-start">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers" data-v-5522efca=""><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                                        </div>
                                        <div class="media-body ">
                                            <div class="data-info ">
                                                <h6 class="mt-1 ">Calls History</h6>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>

                            <li role="presentation">
                                <hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider">
                            </li>

                            <li role="presentation">
                                <a href="javascript:;" class="dropdown-item">
                                    <div class="media server-log ">
                                        <div class="media-aside align-self-start">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text" data-v-5522efca=""><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                                        </div>
                                        <div class="media-body ">
                                            <div class="data-info ">
                                                <h6 class="mt-1 ">Manage Phone Numbers</h6>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li role="presentation">
                                <hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider">
                            </li>

                            <li role="presentation">
                                <a href="javascript:;" class="dropdown-item">
                                    <div class="media server-log ">
                                        <div class="media-aside align-self-start">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-list" data-v-5522efca=""><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                                        </div>
                                        <div class="media-body ">
                                            <div class="data-info ">
                                                <h6 class="mt-1 ">Manage Main IVR Menu</h6>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li role="presentation">
                                <hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider">
                            </li>

                            <li role="presentation">
                                <a href="javascript:;" @click="addAdditinalPhoneNumber" class="dropdown-item">
                                    <div class="media server-log ">
                                        <div class="media-aside align-self-start">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus" data-v-5522efca=""><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                        </div>
                                        <div class="media-body ">
                                            <div class="data-info ">
                                                <h6 class="mt-1 ">Add Phone Number</h6>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            
                           
                            
                            
                            </ul></div>

                    <div class="dropdown nav-item user-profile-dropdown notification-dropdown btn-group">
                        
                        
                        
                        <a v-if="user.is_authorized" href="javascript:;" id="ddluser" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            
                            <client-only>
                                
                                <template v-if="'avatar' in user">
                                    
                                    <div class="avatar avatar-sm avatar-secondary mt-1 me-0">
                                        <img class="avatar-title rounded " :src="user.avatar" alt="avatar" />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="avatar avatar-sm avatar-secondary me-0" :class="color">
                                        <span class="avatar-title rounded-circle">{{ user.initials }}</span>
                                    </div>
                                </template>
                            </client-only>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right navbar-nav" aria-labelledby="ddluser">
                            <li role="presentation" class="text-center">
                                <client-only>
                                <router-link to="/users/profile" class="dropdown-item">
                                    <h5>{{ user.firstname }} {{ user.lastname }}</h5>
                                    <h6>{{ user.email }}</h6>
                                </router-link>
                            </client-only>
                            </li>
                            <hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider" />
                            <li role="presentation" class="text-end">
                                <button class="btn btn-sm mt-2 mb-2 me-3" @click="signOut()"> SIGN OUT </button>
                            </li>
                        </ul>
                    </div>
                    
                    
                </div>

                
                
            </header>
            
            <div v-if="company.mode=='test'" class="alert   alert-light-danger alert-dismissible  mt-3 ms-4 me-3" role="alert">
                    <div class="ms-5 me-5">
                        While in testing mode, you can test the inbound functionality of your call centers by dialing <strong>+1 (858) 330-4450 </strong> and entering your assigned Testing PIN for the desired function, as provided below. Once you upgrade, a direct phone number will be assigned, and you will be able to dial in without PIN codes. Calls made with a PIN code are not billable but are limited to 3 minutes.
                        <br>
                    <div class="text-end">
                        <button @click="upgrade" class="btn btn-outline-secondary ">Upgrade to production</button>
                    </div>
                                                    
                    </div>
                </div>
        </div>
<!-- {{company}} -->
        
    </div>
</client-only>
<!-- {{company}} -->
</template>

<script setup>
import './assets/sass/elements/avatar.scss';
import '~/assets/sass/widgets/widgets.scss';

import { loadStripe } from '@stripe/stripe-js' 

const backendUrl = '/api/protected/stripe/session'
const loading = ref(false)

  

// const { $subscribe } = useNuxtApp(); // Notification web scoket
// if (process.client){
//     $subscribe(sessionStorage.getItem("account_id"));
//     console.log("Subscribed to events: ", sessionStorage.getItem("account_id"))
// }

const user = useUser();
user.value.is_authorized = true;
const dataIsChanged = useDataChanged();
const { $swal, $socket, $get} = useNuxtApp();

const modalTitle = ref("");
let PaymentModal = ref(null);
let phoneSelector = ref(null);
let payment_type = ref("included_did");
const nextButtonCaption = ref("NEXT: Payment details");
const paymentParams = ref({});

const screen = ref("number_selection");


const addAdditinalPhoneNumber = () => {
    const screen = ref("number_selection");
    paymentParams.value = {paymentSettings:{subscription:5,service:"additional_did"}};
    modalTitle.value = "Purchase additional Phone Number";
    
   
    
    payment_type.value="additional_did";
    PaymentModal.value.openModal(); 
    phoneSelector.value.getRandomNumber();
    
}


if (process.client){
    if ($socket) {
        const { socket, init, subscribe } = $socket;
        init((err) => {
            if (err) {
            console.error(err);
            } else {
            console.log('Initialization successful');
            subscribe(sessionStorage.getItem('account_id'));
            }
        });
    } else {
        console.error('Socket plugin not found');
    }
}

const openStripePotal = async() => {
    let res = await $get("/api/protected/stripe/portal");
    // console.log(res.session.url);
    // navigateTo(res.session.url)
    window.location.href = res.session.url;
}

let color = ref("");

watch(() => user.value,  (newValue) => {
    if (!((newValue.firstname == undefined) && (newValue.lastname == undefined)) ) {
        user.value.initials = newValue.firstname[0]+newValue.lastname[0];
        user.value.has_avatar = false;
        color.value = getUserIdColor(newValue.email)
    }
    
}, { deep: true, immediate:true })

const company = useCompany();

let bc = useBreadcrumbs();
    // bc.value = [{ name: "Main page", is_current: true, url: "none" }];


function getUserIdColor(userId) {
    function hashCode(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
    const colors = ['avatar-primary', 'avatar-secondary', 'avatar-danger']; // List of available colors
    const colorIndex = hashCode(userId) % colors.length; // Use hash code of user ID to get an index
    
    return colors[colorIndex];
}

const openLink = (url) => {
    
    if (!(url == 'none' || url == '')) {
        if ( dataIsChanged.value == true) {
            $swal.fire({
            position: "top-end",
            icon: "warning",
            text: "You are currently in the process of adjusting settings. Please choose either to reset or save the changes.",
            showConfirmButton: false,
            timer: 2500
        });
        } else {
            navigateTo(url);
        }
    }
}

const signOut = async () => {
    if(process.client) {
        sessionStorage.removeItem('token')
    }
    await navigateTo("/login")
}

const upgrade = () => {
    modalTitle.value = "Upgrade account to Production";
    paymentParams.value = {paymentSettings:{allowed:true, subscription:20, topup: 30, type:"included_did"}};
    PaymentModal.value.openModal();
    payment_type.value="included_did";
    phoneSelector.value.getRandomNumber();
}

const next = async () => {


    
    
    // alert(screen.value)
    switch (screen.value){
        case "number_selection":
            screen.value = "invoice";
            nextButtonCaption.value = "NEXT: Checkout"
        break;

        case "invoice":
            // nextButtonCaption.value = "NEXT: Checkout"
            sessionStorage.setItem("selectedDID", paymentParams.value.SelectedNumber.phoneNumber);
            sessionStorage.setItem("topupAllowed", paymentParams.value.paymentSettings.allowed);
            sessionStorage.setItem("topupAmount", paymentParams.value.paymentSettings.topup);
            startCheckout();
        break;

        
    //     case "credit_card":
    //         let token = await creitCard.value.handleSubmit();
    //         console.log(token)
    //     break;
    }
    // if (screen.value == "invoice"){

    // }
    // alert("Yes");
}

const startCheckout = async () => {
    try {
      loading.value = true
      let url = backendUrl;
      let topup_allowed = paymentParams.value.paymentSettings.allowed;
      if (topup_allowed ){
        url+= "?topup=true&amount="+paymentParams.value.paymentSettings.topup+"&service="+paymentParams.value.paymentSettings.service;
      } else {
        url+= "?topup=false&service="+paymentParams.value.paymentSettings.service;
      }
      // Fetch the checkout session from the backend
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem("token")
        }
      })
  
      const { sessionId } = await response.json()
  
      // Load Stripe.js with your publishable key
      const stripe = await loadStripe('pk_test_51NIPLiIHvM6IbKomtCvHiQcP1TunrBOsSLrCovQGd9jSLRZsQt4ebxKduGByjWZ9Ga2xFJqOD2UrXKsZtVY1yj7q000DRYg1cK')
  
      // Redirect to the Stripe Checkout page using the sessionId
      const { error } = await stripe.redirectToCheckout({ sessionId })
  
      if (error) {
        console.error('Stripe Checkout error:', error)
        loading.value = false
      }
    } catch (error) {
      console.error('Checkout error:', error)
      loading.value = false
    }
  }


</script>